/* ! picturefill - v3.0.2 - 2016-02-12
 * https://scottjehl.github.io/picturefill/
 * footer__copyright (c) 2016 https://github.com/scottjehl/picturefill/blob/master/Authors.txt; Licensed MIT
 */
!function (a) {
  let b = navigator.userAgent; a.HTMLPictureElement && /ecko/.test(b) && b.match(/rv\:(\d+)/) && RegExp.$1 < 45 && addEventListener('resize', function () {
    let b; let c = document.createElement('source'); let d = function (a) {
      let b; let d; let e = a.parentNode; e.nodeName.toUpperCase() === 'PICTURE' ? (b = c.cloneNode(), e.insertBefore(b, e.firstElementChild), setTimeout(function () {
        e.removeChild(b);
      })) : (!a._pfLastSize || a.offsetWidth > a._pfLastSize) && (a._pfLastSize = a.offsetWidth, d = a.sizes, a.sizes += ',100vw', setTimeout(function () {
        a.sizes = d;
      }));
    }; let e = function () {
      let a; let b = document.querySelectorAll('picture > img, img[srcset][sizes]'); for (a = 0; a < b.length; a++) {
        d(b[a]);
      }
    }; let f = function () {
      clearTimeout(b), b = setTimeout(e, 99);
    }; let g = a.matchMedia && matchMedia('(orientation: landscape)'); let h = function () {
      f(), g && g.addListener && g.addListener(f);
    }; return c.srcset = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==', /^[c|i]|d$/.test(document.readyState || '') ? h() : document.addEventListener('DOMContentLoaded', h), f;
  }());
}(window), function (a, b, c) {
  function d(a) {
    return a === ' ' || a === '	' || a === '\n' || a === '\f' || a === '\r';
  } function e(b, c) {
    let d = new a.Image(); return d.onerror = function () {
      A[b] = !1, ba();
    }, d.onload = function () {
      A[b] = d.width === 1, ba();
    }, d.src = c, 'pending';
  } function f() {
    M = !1, P = a.devicePixelRatio, N = {}, O = {}, s.DPR = P || 1, Q.width = Math.max(a.innerWidth || 0, z.clientWidth), Q.height = Math.max(a.innerHeight || 0, z.clientHeight), Q.vw = Q.width / 100, Q.vh = Q.height / 100, r = [Q.height, Q.width, P].join('-'), Q.em = s.getEmValue(), Q.rem = Q.em;
  } function g(a, b, c, d) {
    let e; let f; let g; let h; return B.algorithm === 'saveData' ? a > 2.7 ? h = c + 1 : (f = b - c, e = Math.pow(a - 0.6, 1.5), g = f * e, d && (g += 0.1 * e), h = a + g) : h = c > 1 ? Math.sqrt(a * b) : a, h > c;
  } function h(a) {
    let b; let c = s.getSet(a); let d = !1; c !== 'pending' && (d = r, c && (b = s.setRes(c), s.applySetCandidate(b, a))), a[s.ns].evaled = d;
  } function i(a, b) {
    return a.res - b.res;
  } function j(a, b, c) {
    let d; return !c && b && (c = a[s.ns].sets, c = c && c[c.length - 1]), d = k(b, c), d && (b = s.makeUrl(b), a[s.ns].curSrc = b, a[s.ns].curCan = d, d.res || aa(d, d.set.sizes)), d;
  } function k(a, b) {
    let c; let d; let e; if (a && b) {
      for (e = s.parseSet(b), a = s.makeUrl(a), c = 0; c < e.length; c++) {
        if (a === s.makeUrl(e[c].url)) {
          d = e[c]; break;
        }
      }
    } return d;
  } function l(a, b) {
    let c; let d; let e; let f; let g = a.getElementsByTagName('source'); for (c = 0, d = g.length; d > c; c++) {
      e = g[c], e[s.ns] = !0, f = e.getAttribute('srcset'), f && b.push({srcset: f, media: e.getAttribute('media'), type: e.getAttribute('type'), sizes: e.getAttribute('sizes'),});
    }
  } function m(a, b) {
    function c(b) {
      let c; let d = b.exec(a.substring(m)); return d ? (c = d[0], m += c.length, c) : void 0;
    } function e() {
      let a; let c; let d; let e; let f; let i; let j; let k; let l; let m = !1; let o = {}; for (e = 0; e < h.length; e++) {
        f = h[e], i = f[f.length - 1], j = f.substring(0, f.length - 1), k = parseInt(j, 10), l = parseFloat(j), X.test(j) && i === 'w' ? ((a || c) && (m = !0), k === 0 ? m = !0 : a = k) : Y.test(j) && i === 'x' ? ((a || c || d) && (m = !0), l < 0 ? m = !0 : c = l) : X.test(j) && i === 'h' ? ((d || c) && (m = !0), k === 0 ? m = !0 : d = k) : m = !0;
      }m || (o.url = g, a && (o.w = a), c && (o.d = c), d && (o.h = d), d || c || a || (o.d = 1), o.d === 1 && (b.has1x = !0), o.set = b, n.push(o));
    } function f() {
      for (c(T), i = '', j = 'in descriptor'; ;) {
        if (k = a.charAt(m), j === 'in descriptor') {
          if (d(k)) {
            i && (h.push(i), i = '', j = 'after descriptor');
          } else {
            if (k === ',') {
              return m += 1, i && h.push(i), void e();
            } if (k === '(') {
              i += k, j = 'in parens';
            } else {
              if (k === '') {
                return i && h.push(i), void e();
              } i += k;
            }
          }
        } else if (j === 'in parens') {
          if (k === ')') {
            i += k, j = 'in descriptor';
          } else {
            if (k === '') {
              return h.push(i), void e();
            } i += k;
          }
        } else if (j === 'after descriptor') {
          if (d(k)) { } else {
            if (k === '') {
              return void e();
            } j = 'in descriptor', m -= 1;
          }
        }m += 1;
      }
    } for (var g, h, i, j, k, l = a.length, m = 0, n = []; ;) {
      if (c(U), m >= l) {
        return n;
      } g = c(V), h = [], g.slice(-1) === ',' ? (g = g.replace(W, ''), e()) : f();
    }
  } function n(a) {
    function b(a) {
      function b() {
        f && (g.push(f), f = '');
      } function c() {
        g[0] && (h.push(g), g = []);
      } for (var e, f = '', g = [], h = [], i = 0, j = 0, k = !1; ;) {
        if (e = a.charAt(j), e === '') {
          return b(), c(), h;
        } if (k) {
          if (e === '*' && a[j + 1] === '/') {
            k = !1, j += 2, b(); continue;
          }j += 1;
        } else {
          if (d(e)) {
            if (a.charAt(j - 1) && d(a.charAt(j - 1)) || !f) {
              j += 1; continue;
            } if (i === 0) {
              b(), j += 1; continue;
            }e = ' ';
          } else if (e === '(') {
            i += 1;
          } else if (e === ')') {
            i -= 1;
          } else {
            if (e === ',') {
              b(), c(), j += 1; continue;
            } if (e === '/' && a.charAt(j + 1) === '*') {
              k = !0, j += 2; continue;
            }
          }f += e, j += 1;
        }
      }
    } function c(a) {
      return k.test(a) && parseFloat(a) >= 0 ? !0 : l.test(a) ? !0 : a === '0' || a === '-0' || a === '+0' ? !0 : !1;
    } let e; let f; let g; let h; let i; let j; var k = /^(?:[+-]?[0-9]+|[0-9]*\.[0-9]+)(?:[eE][+-]?[0-9]+)?(?:ch|cm|em|ex|in|mm|pc|pt|px|rem|vh|vmin|vmax|vw)$/i; var l = /^calc\((?:[0-9a-z \.\+\-\*\/\(\)]+)\)$/i; for (f = b(a), g = f.length, e = 0; g > e; e++) {
      if (h = f[e], i = h[h.length - 1], c(i)) {
        if (j = i, h.pop(), h.length === 0) {
          return j;
        } if (h = h.join(' '), s.matchesMedia(h)) {
          return j;
        }
      }
    } return '100vw';
  }b.createElement('picture'); let o; let p; let q; let r; var s = {}; let t = !1; let u = function () {}; let v = b.createElement('img'); let w = v.getAttribute; let x = v.setAttribute; let y = v.removeAttribute; var z = b.documentElement; var A = {}; var B = {algorithm: '',}; let C = 'data-pfsrc'; let D = C + 'set'; let E = navigator.userAgent; let F = /rident/.test(E) || /ecko/.test(E) && E.match(/rv\:(\d+)/) && RegExp.$1 > 35; let G = 'currentSrc'; let H = /\s+\+?\d+(e\d+)?w/; let I = /(\([^)]+\))?\s*(.+)/; let J = a.picturefillCFG; let K = 'position:absolute;left:0;visibility:hidden;display:block;padding:0;border:none;font-size:1em;width:1em;overflow:hidden;clip:rect(0px, 0px, 0px, 0px)'; let L = 'font-size:100%!important;'; var M = !0; var N = {}; var O = {}; var P = a.devicePixelRatio; var Q = {'px': 1, 'in': 96,}; let R = b.createElement('a'); let S = !1; var T = /^[ \t\n\r\u000c]+/; var U = /^[, \t\n\r\u000c]+/; var V = /^[^ \t\n\r\u000c]+/; var W = /[,]+$/; var X = /^\d+$/; var Y = /^-?(?:[0-9]+|[0-9]*\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/; let Z = function (a, b, c, d) {
    a.addEventListener ? a.addEventListener(b, c, d || !1) : a.attachEvent && a.attachEvent('on' + b, c);
  }; let $ = function (a) {
    let b = {}; return function (c) {
      return c in b || (b[c] = a(c)), b[c];
    };
  }; let _ = function () {
    let a = /^([\d\.]+)(em|vw|px)$/; let b = function () {
      for (var a = arguments, b = 0, c = a[0]; ++b in a;) {
        c = c.replace(a[b], a[++b]);
      } return c;
    }; let c = $(function (a) {
      return 'return ' + b((a || '').toLowerCase(), /\band\b/g, '&&', /,/g, '||', /min-([a-z-\s]+):/g, 'e.$1>=', /max-([a-z-\s]+):/g, 'e.$1<=', /calc([^)]+)/g, '($1)', /(\d+[\.]*[\d]*)([a-z]+)/g, '($1 * e.$2)', /^(?!(e.[a-z]|[0-9\.&=|><\+\-\*\(\)\/])).*/gi, '') + ';';
    }); return function (b, d) {
      let e; if (!(b in N)) {
        if (N[b] = !1, d && (e = b.match(a))) {
          N[b] = e[1] * Q[e[2]];
        } else {
          try {
            N[b] = new Function('e', c(b))(Q);
          } catch (f) {}
        }
      } return N[b];
    };
  }(); var aa = function (a, b) {
    return a.w ? (a.cWidth = s.calcListLength(b || '100vw'), a.res = a.w / a.cWidth) : a.res = a.d, a;
  }; var ba = function (a) {
    if (t) {
      let c; let d; let e; let f = a || {}; if (f.elements && f.elements.nodeType === 1 && (f.elements.nodeName.toUpperCase() === 'IMG' ? f.elements = [f.elements] : (f.context = f.elements, f.elements = null)), c = f.elements || s.qsa(f.context || b, f.reevaluate || f.reselect ? s.sel : s.selShort), e = c.length) {
        for (s.setupRun(f), S = !0, d = 0; e > d; d++) {
          s.fillImg(c[d], f);
        }s.teardownRun(f);
      }
    }
  }; o = a.console && console.warn ? function (a) {
    console.warn(a);
  } : u, G in v || (G = 'src'), A['image/jpeg'] = !0, A['image/gif'] = !0, A['image/png'] = !0, A['image/svg+xml'] = b.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'), s.ns = ('pf' + (new Date()).getTime()).substr(0, 9), s.supSrcset = 'srcset' in v, s.supSizes = 'sizes' in v, s.supPicture = !!a.HTMLPictureElement, s.supSrcset && s.supPicture && !s.supSizes && !function (a) {
    v.srcset = 'data:,a', a.src = 'data:,a', s.supSrcset = v.complete === a.complete, s.supPicture = s.supSrcset && s.supPicture;
  }(b.createElement('img')), s.supSrcset && !s.supSizes ? !function () {
    let a = 'data:image/gif;base64,R0lGODlhAgABAPAAAP///wAAACH5BAAAAAAALAAAAAACAAEAAAICBAoAOw=='; let c = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='; let d = b.createElement('img'); let e = function () {
      let a = d.width; a === 2 && (s.supSizes = !0), q = s.supSrcset && !s.supSizes, t = !0, setTimeout(ba);
    }; d.onload = e, d.onerror = e, d.setAttribute('sizes', '9px'), d.srcset = c + ' 1w,' + a + ' 9w', d.src = c;
  }() : t = !0, s.selShort = 'picture>img,img[srcset]', s.sel = s.selShort, s.cfg = B, s.DPR = P || 1, s.u = Q, s.types = A, s.setSize = u, s.makeUrl = $(function (a) {
    return R.href = a, R.href;
  }), s.qsa = function (a, b) {
    return 'querySelector' in a ? a.querySelectorAll(b) : [];
  }, s.matchesMedia = function () {
    return a.matchMedia && (matchMedia('(min-width: 0.1em)') || {}).matches ? s.matchesMedia = function (a) {
      return !a || matchMedia(a).matches;
    } : s.matchesMedia = s.mMQ, s.matchesMedia.apply(this, arguments);
  }, s.mMQ = function (a) {
    return a ? _(a) : !0;
  }, s.calcLength = function (a) {
    let b = _(a, !0) || !1; return b < 0 && (b = !1), b;
  }, s.supportsType = function (a) {
    return a ? A[a] : !0;
  }, s.parseSize = $(function (a) {
    let b = (a || '').match(I); return {media: b && b[1], length: b && b[2], };
  }), s.parseSet = function (a) {
    return a.cands || (a.cands = m(a.srcset, a)), a.cands;
  }, s.getEmValue = function () {
    let a; if (!p && (a = b.body)) {
      let c = b.createElement('div'); let d = z.style.cssText; let e = a.style.cssText; c.style.cssText = K, z.style.cssText = L, a.style.cssText = L, a.appendChild(c), p = c.offsetWidth, a.removeChild(c), p = parseFloat(p, 10), z.style.cssText = d, a.style.cssText = e;
    } return p || 16;
  }, s.calcListLength = function (a) {
    if (!(a in O) || B.uT) {
      let b = s.calcLength(n(a)); O[a] = b ? b : Q.width;
    } return O[a];
  }, s.setRes = function (a) {
    let b; if (a) {
      b = s.parseSet(a); for (let c = 0, d = b.length; d > c; c++) {
        aa(b[c], a.sizes);
      }
    } return b;
  }, s.setRes.res = aa, s.applySetCandidate = function (a, b) {
    if (a.length) {
      let c; let d; let e; let f; let h; let k; let l; let m; let n; let o = b[s.ns]; let p = s.DPR; if (k = o.curSrc || b[G], l = o.curCan || j(b, k, a[0].set), l && l.set === a[0].set && (n = F && !b.complete && l.res - 0.1 > p, n || (l.cached = !0, l.res >= p && (h = l))), !h) {
        for (a.sort(i), f = a.length, h = a[f - 1], d = 0; f > d; d++) {
          if (c = a[d], c.res >= p) {
            e = d - 1, h = a[e] && (n || k !== s.makeUrl(c.url)) && g(a[e].res, c.res, p, a[e].cached) ? a[e] : c; break;
          }
        }
      }h && (m = s.makeUrl(h.url), o.curSrc = m, o.curCan = h, m !== k && s.setSrc(b, h), s.setSize(b));
    }
  }, s.setSrc = function (a, b) {
    let c; a.src = b.url, b.set.type === 'image/svg+xml' && (c = a.style.width, a.style.width = a.offsetWidth + 1 + 'px', a.offsetWidth + 1 && (a.style.width = c));
  }, s.getSet = function (a) {
    let b; let c; let d; let e = !1; let f = a[s.ns].sets; for (b = 0; b < f.length && !e; b++) {
      if (c = f[b], c.srcset && s.matchesMedia(c.media) && (d = s.supportsType(c.type))) {
        d === 'pending' && (c = d), e = c; break;
      }
    } return e;
  }, s.parseSets = function (a, b, d) {
    let e; let f; let g; let h; let i = b && b.nodeName.toUpperCase() === 'PICTURE'; let j = a[s.ns]; (j.src === c || d.src) && (j.src = w.call(a, 'src'), j.src ? x.call(a, C, j.src) : y.call(a, C)), (j.srcset === c || d.srcset || !s.supSrcset || a.srcset) && (e = w.call(a, 'srcset'), j.srcset = e, h = !0), j.sets = [], i && (j.pic = !0, l(b, j.sets)), j.srcset ? (f = {srcset: j.srcset, sizes: w.call(a, 'sizes'), }, j.sets.push(f), g = (q || j.src) && H.test(j.srcset || ''), g || !j.src || k(j.src, f) || f.has1x || (f.srcset += ', ' + j.src, f.cands.push({url: j.src, d: 1, set: f, }))) : j.src && j.sets.push({srcset: j.src, sizes: null, }), j.curCan = null, j.curSrc = c, j.supported = !(i || f && !s.supSrcset || g && !s.supSizes), h && s.supSrcset && !j.supported && (e ? (x.call(a, D, e), a.srcset = '') : y.call(a, D)), j.supported && !j.srcset && (!j.src && a.src || a.src !== s.makeUrl(j.src)) && (j.src === null ? a.removeAttribute('src') : a.src = j.src), j.parsed = !0;
  }, s.fillImg = function (a, b) {
    let c; let d = b.reselect || b.reevaluate; a[s.ns] || (a[s.ns] = {}), c = a[s.ns], (d || c.evaled !== r) && ((!c.parsed || b.reevaluate) && s.parseSets(a, a.parentNode, b), c.supported ? c.evaled = r : h(a));
  }, s.setupRun = function () {
    (!S || M || P !== a.devicePixelRatio) && f();
  }, s.supPicture ? (ba = u, s.fillImg = u) : !function () {
    let c; let d = a.attachEvent ? /d$|^c/ : /d$|^c|^i/; var e = function () {
      let a = b.readyState || ''; f = setTimeout(e, a === 'loading' ? 200 : 999), b.body && (s.fillImgs(), c = c || d.test(a), c && clearTimeout(f));
    }; var f = setTimeout(e, b.body ? 9 : 99); let g = function (a, b) {
      let c; let d; var e = function () {
        let f = new Date() - d; b > f ? c = setTimeout(e, b - f) : (c = null, a());
      }; return function () {
        d = new Date(), c || (c = setTimeout(e, b));
      };
    }; let h = z.clientHeight; let i = function () {
      M = Math.max(a.innerWidth || 0, z.clientWidth) !== Q.width || z.clientHeight !== h, h = z.clientHeight, M && s.fillImgs();
    }; Z(a, 'resize', g(i, 99)), Z(b, 'readystatechange', e);
  }(), s.picturefill = ba, s.fillImgs = ba, s.teardownRun = u, ba._ = s, a.picturefillCFG = {pf: s, push(a) {
    let b = a.shift(); typeof s[b] === 'function' ? s[b].apply(s, a) : (B[b] = a[0], S && s.fillImgs({reselect: !0,}));
  }, }; for (;J && J.length;) {
    a.picturefillCFG.push(J.shift());
  }a.picturefill = ba, typeof module === 'object' && typeof module.exports === 'object' ? module.exports = ba : typeof define === 'function' && define.amd && define('picturefill', function () {
    return ba;
  }), s.supPicture || (A['image/webp'] = e('image/webp', 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA=='));
}(window, document);
